import React from "react";

interface ModalContainerProps {
  height: number;
  children: React.ReactNode;
}

const ModalContainer = ({ height, children }: ModalContainerProps) => {
  return (
    <div
      className="modal fade show"
      tabIndex={-1}
      style={{ display: "block", zIndex: 1055 }} // Increase zIndex for the second modal
    >
      <div
        className="Modal__dialog  animated slideInDown md-lg-modal"
        role="document"
      >
        <div className="Modal__content">
          <div
            className="Modal__body"
            style={{
              height: `${height}rem`,
              overflowY: "auto",
              position: "relative",
            }}
          >
            {React.Children.map(children, (child, i) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                  key: i,
                  height,
                });
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalContainer;
