import React, { Dispatch, SetStateAction, useState } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment/moment";
import { formatMoney } from "../../../helpers/formatter";
import styles from "./LoanOffer.module.scss";
import { Button, message } from "antd";
import { LoanOfferProps } from "../Loans/Disburse/interface";
import ModalContainer from "../ModalContainer";
import { postData } from "../../../newApis/apiMethods";
import * as ajaxEndpoint from "../../../api/ajax-endpoints";
import { RESEND_LOAN_OFFER } from "../../../api/ajax-endpoints";

enum LoanOfferStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
}

interface LoanOfferModalProps {
  setShowLoanOffer: Dispatch<SetStateAction<boolean>>;
  loan_offers: LoanOfferProps[];
  isLoadingLoanOffers: boolean;
}

interface OfferResendLoaderProp {
  [isLoadingOfferResendId: number]: boolean;
}

const LoanOfferModal = ({
  setShowLoanOffer,
  loan_offers,
  isLoadingLoanOffers,
}: LoanOfferModalProps) => {
  const [isLoadingOfferResend, setIsLoadingOfferResend] =
    useState<OfferResendLoaderProp | null>(null);

  function closeModalHandler() {
    setShowLoanOffer(false);
  }

  const handleResendOffer = async (loan_id: number, loan_offer_id: number) => {
    setIsLoadingOfferResend((prev) => ({ ...prev, [loan_offer_id]: true }));

    try {
      const response = await postData(ajaxEndpoint.RESEND_LOAN_OFFER, {
        loan_id,
        loan_offer_id,
      });

      if (response?.status) {
        message.success(
          response?.message + "!" + " " + "Loan Offer successfully resent",
        );
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error.response?.data?.message);
    } finally {
      setIsLoadingOfferResend((prev) => ({ ...prev, [loan_offer_id]: false }));
    }
  };

  let RenderedComponent = null;
  if (isLoadingLoanOffers) {
    RenderedComponent = <p>Loading...</p>;
  } else if (loan_offers?.length === 0) {
    RenderedComponent = <p>No loan offer has been sent</p>;
  } else {
    RenderedComponent = (
      <>
        <MDBTable
          responsive
          minimal="true"
          striped
          bordered
          hover
          id="table-to-xls"
        >
          <MDBTableHead>
            <tr>
              <th>S/N</th>
              <th>Creation Date</th>
              <th>Requested Amount</th>
              <th>Offer Amount</th>
              <th>Requested Tenor</th>
              <th>Offer Tenor</th>
              <th>Status</th>
              <th>Acceptance/Rejection Date</th>
              <th>Comment</th>
              <th>Created By</th>
              <th>Action</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {loan_offers?.map((loanOffer, index) => (
              <tr key={loanOffer.id}>
                <td>{index + 1}</td>
                <td>
                  {moment(loanOffer?.created_at).format("DD-MMM-YYYY, h:mm a")}
                </td>
                <td>{formatMoney(loanOffer?.requested_amount)}</td>
                <td>{formatMoney(loanOffer?.offer_amount)}</td>
                <td>{loanOffer?.requested_tenure}</td>
                <td>{loanOffer?.offer_tenure}</td>
                <td
                  className={
                    loanOffer?.status === LoanOfferStatus.Pending
                      ? styles.pending
                      : loanOffer?.status === LoanOfferStatus.Accepted
                        ? styles.accepted
                        : styles.rejected
                  }
                >
                  {loanOffer?.status}
                </td>
                <td>
                  {loanOffer?.updated_at
                    ? moment(loanOffer?.updated_at).format(
                        "DD-MMM-YYYY, h:mm a",
                      )
                    : "Nil"}
                </td>
                <td>{loanOffer?.comment}</td>
                <td>{loanOffer?.created_by ? loanOffer?.created_by : "Nil"}</td>
                <td>
                  <Button
                    onClick={() =>
                      handleResendOffer(
                        loanOffer.requested_loan_id,
                        loanOffer.id,
                      )
                    }
                    loading={isLoadingOfferResend?.[loanOffer.id]}
                  >
                    Resend Offer
                  </Button>
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </>
    );
  }

  return (
    <ModalContainer height={25}>
      <h4 className="d-flex justify-content-center mb-2">Loan Offer</h4>
      <div className="d-flex justify-content-between">{RenderedComponent}</div>
      <div style={{ position: "absolute", right: "10px", top: "10px" }}>
        <Button className={styles.view_offer_btn} onClick={closeModalHandler}>
          Close
        </Button>
      </div>
    </ModalContainer>
  );
};

export default LoanOfferModal;
