import React, { Dispatch, SetStateAction, useState } from "react";
import styles from "./EquityContribution.module.scss";
import { Button, Input, message } from "antd";
import * as apiEndpoint from "../../../api/ajax-endpoints";
import { getData } from "../../../newApis/apiMethods";
import ModalContainer from "../ModalContainer";

interface EquityContributionModalProps {
  setShowEquityContribution: Dispatch<SetStateAction<boolean>>;
  require_equity_contribution: boolean;
  equity_contribution_amount: number;
  equity_contribution_amount_paid: number;
  total_cost_amount: number;
  cost_discount_percentage: number;
  equity_contribution_percentage: number;
  description: string;
  account_number: string;
  account_name: string;
  wallet_id: string;
  consumer_type: string;
  wallet_provider_id: number;
  target_id: number;
  tenant_id: null | number | string;
  loan_ref: string;
}

const EquityContribution = ({
  setShowEquityContribution,
  require_equity_contribution,
  equity_contribution_amount,
  equity_contribution_amount_paid,
  total_cost_amount,
  cost_discount_percentage,
  equity_contribution_percentage,
  description,
  account_number,
  account_name,
  loan_ref,
}: EquityContributionModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  function closeModalHandler() {
    setShowEquityContribution(false);
  }

  async function notifyCustomerForEquityContribution() {
    setIsLoading(true);
    try {
      const response = await getData(
        `${apiEndpoint.NOTIFY_CUSTOMER}?loanRef=${loan_ref}`,
      );
      if (response.status_code === 200) {
        message.success(response.message);
      } else if (
        response.response.status === 500 ||
        response.status_code === 500
      ) {
        message.error("Internal server error");
      } else {
        message.error("An error occurred. Please try again later.");
      }
    } catch (error) {
      message.error(error.data.response.message || error.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <ModalContainer height={28}>
      <h4
        className="d-flex justify-content-start mb-3"
        style={{ color: "#170D33", fontWeight: "bold", fontSize: "20px" }}
      >
        Equity Contribution
      </h4>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "10px",
        }}
      >
        <div>
          <label className="text-muted">Item Description</label>
          <Input defaultValue={description} readOnly size="large" />
        </div>
        <div>
          <label className="text-muted">Item Amount</label>
          <Input
            defaultValue={new Intl.NumberFormat("en-NG", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(total_cost_amount)}
            readOnly
            addonAfter="NGN"
            size="large"
          />
        </div>
        <div>
          <label className="text-muted">Equity Percentage</label>
          <Input
            defaultValue={equity_contribution_percentage}
            readOnly
            addonAfter="%"
            size="large"
          />
        </div>
        <div>
          <label className="text-muted">Equity Amount</label>
          <Input
            defaultValue={new Intl.NumberFormat("en-NG", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(equity_contribution_amount)}
            readOnly
            addonAfter="NGN"
            size="large"
          />
        </div>
        <div>
          <label className="text-muted">Discount Applied</label>
          <Input
            defaultValue={cost_discount_percentage}
            readOnly
            addonAfter="%"
            size="large"
          />
        </div>
        <div>
          <label className="text-muted">Account name</label>
          <Input readOnly defaultValue={account_name} size="large" />
        </div>
        <div>
          <label className="text-muted">Account Number</label>
          <Input defaultValue={account_number} readOnly size="large" />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        {/*<Button*/}
        {/*  className={styles.sweep_equity_contribution_btn}*/}
        {/*  size="large"*/}
        {/*>*/}
        {/*  Sweep Funds*/}
        {/*</Button>*/}
        <Button
          className={styles.sweep_equity_contribution_btn}
          size="large"
          onClick={notifyCustomerForEquityContribution}
          disabled={isLoading}
          loading={isLoading}
        >
          Notify Customer
        </Button>
        <Button
          className={styles.close_equity_contribution_btn}
          onClick={closeModalHandler}
          size="large"
        >
          Close
        </Button>
      </div>
    </ModalContainer>
  );
};

export default EquityContribution;
